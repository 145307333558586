<template>
  <router-link v-if="projectImpact" :to="{name: 'ProjectImpact', params: {id: projectImpact.id}}">
    {{ projectImpact.name }}
  </router-link>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
export default {
  name: "ProjectImpactLink",

  props: {
    projectImpact: {type: Object, default: () => null}
  }
}
</script>

<style scoped>

</style>
